import axios from "axios";
import uuid from "uuid/v4";

class API {
  constructor() {
    this.extendTokenEndpoint = process.env.EXTEND_TOKEN;
    this.jobReceiverEndpoint = process.env.JOB_RECEIVER;
  }

  async extendToken(userId, shortLiveToken) {
    try {
      let response = await axios.post(this.extendTokenEndpoint, {
        user_id: userId,
        short_live_token: shortLiveToken
      });
      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async sendJob(pageId, startDate, endDate, email, longLiveToken) {
    try {
      let response = await axios.post(this.jobReceiverEndpoint, {
        page_id: pageId,
        // user_token: longLiveToken,
        user_token:
          "EAAVA5OEIChwBAHNrg0eWW1SfWHSLw12XDMHubiofCu8RCC1T6Y1pI8iKZAD3FGQhx0BaehaQ8RCbJWY2itZCu2TOSgZBFTeFDVa6ivSlT87svU4edjF5lD2rndvegNclZAgg8k3ozZBMqipBZC8On5GElwhwyGZBzUXZChsZAhpZB89QZDZD",
        start_date: startDate,
        end_date: endDate,
        email,
        uid: uuid()
      });
      return response.data;
    } catch (e) {
      throw e;
    }
  }
}

export default new API();
