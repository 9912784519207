import React from "react";

const Pages = ({ pages, onElmClick, selectedPage, classNames }) => {
  const classes = [...new Set(["columns", "is-multiline", ...classNames])];
  return (
    <div className={classes.join(" ")}>
      {pages.map(page => (
        <div className="column is-one-third" key={page.id}>
          <div
            className={`card is-clickable page-card ${
              selectedPage.id === page.id ? "page-selected" : ""
            }`}
            onClick={() => onElmClick(page)}
          >
            <div className="card-content">
              <div className="media">
                <div className="media-left">
                  <figure className="image is-48x48">
                    <img
                      src={`https://graph.facebook.com/${page.id}/picture?type=large&width=720&height=720`}
                      alt={page.name}
                    />
                  </figure>
                </div>
                <div className="media-content">
                  <p className="title is-6">{page.name}</p>
                  <p className="subtitle is-7">{page.category}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Pages;
