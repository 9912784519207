import React, { createContext, useState } from "react";

export const AppContext = createContext();
export const AppContextProvider = ({ children }) => {
  const [token, setToken] = useState(window.localStorage.getItem("mtk_token"));
  const setAppToken = token => {
    setToken(token);
    window.localStorage.setItem("mtk_token", token);
  };
  return (
    <AppContext.Provider value={{ token, setAppToken }}>
      {children}
    </AppContext.Provider>
  );
};
