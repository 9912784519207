import React from "react";
import Layout from "./Layout";

const About = () => (
  <Layout>
    <h1>About</h1>
  </Layout>
);

export default About;
