import React from "react";
import { FaTwitter, FaFacebookSquare, FaYoutube } from "react-icons/fa";
import { IoLogoFacebook, IoLogoTwitter, IoLogoYoutube } from "react-icons/io";

const Footer = () => (
  <footer className="footer">
    <div className="container">
      <div className="content has-text-centered">
        <div className="social-block">
          <a href="https://facebook.com/wisesightglobal">
            <FaFacebookSquare size={"1.4em"} />
          </a>
          <a href="https://twitter.com/wisesightglobal">
            <IoLogoTwitter size={"1.4em"} />
          </a>
          <a href="https://www.youtube.com/channel/UCEHdK5RuA3epopy6pDvLRVQ">
            <IoLogoYoutube size={"1.4em"} />
          </a>
        </div>
        Copyright © 2018. All Right Reserved. Wisesight (Thailand) Co., Ltd.
      </div>
    </div>
  </footer>
);

export default Footer;
