import React, { useState } from "react";
import { Link } from "react-router-dom";

const Nav = () => {
  const [showMenu, setShowMenu] = useState(false);
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  return (
    <nav
      className="navbar has-shadow is-spaced"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="container">
        <div className="navbar-brand">
          <Link className="navbar-item" to="/">
            <h1
              className="title"
              style={{
                backgroundColor: "#3273dc",
                padding: "4px 16px",
                color: "white",
                boxShadow: "4px 4px #eee",
                fontStyle: "italic"
              }}
            >
              WISESIGHT
            </h1>
          </Link>
          <a
            role="button"
            className="navbar-burger burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarHambergur"
            onClick={toggleMenu}
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </a>
        </div>
        <div
          id="navbarHambergur"
          className={`navbar-menu ${showMenu ? "is-active" : ""}`}
        >
          <div className="navbar-start"></div>
          <div className="navbar-end">
            {/* <Link className="navbar-item" to="/about">
              About
            </Link> */}
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link">Products</a>
              <div className="navbar-dropdown">
                <a
                  className="navbar-item"
                  href="https://zocialeye.wisesight.com"
                >
                  ZOCIAL EYE
                </a>
                <a className="navbar-item" href="https://warroom.wisesight.com">
                  WARROOM
                </a>
              </div>
            </div>
            {/* <div className="navbar-item">
              <div className="buttons">
                <a className="button is-link">Login with Facebook</a>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
