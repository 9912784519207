import React, { useContext, useState, useEffect } from "react";
import Loading from "react-loading";
import { Link } from "react-router-dom";
import Layout from "./Layout";
import { AppContext, AppContextProvider } from "../AppContext";
import heroimg from "../assets/images/video.png";
import FB from "../libs/fb";
import API from "../libs/api";

const Home = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const { setAppToken } = useContext(AppContext);

  useEffect(() => {
    (async () => {
      try {
        let response = await FB.getLoginStatus();
        if (response.status === "connected") {
          setIsLoggedIn(true);
          setLoading(false);
        }
      } catch (e) {
        setIsLoggedIn(false);
        setLoading(false);
      }
    })();
  }, []);

  const login = async e => {
    e.preventDefault();
    setLoading(true);
    let loginResponse = await FB.login();
    let apiResponse = await API.extendToken(
      loginResponse.userID,
      loginResponse.accessToken
    );
    setAppToken(apiResponse.long_live_token);
    setIsLoggedIn(true);
    setLoading(false);
  };

  const logout = async e => {
    e.preventDefault();
    await FB.logout();
    setIsLoggedIn(false);
  };

  return (
    <Layout>
      <section className="hero is-medium intro">
        <div className="container">
          <div className="hero-body">
            <div className="columns">
              <div className="column">
                <div className="intro-content">
                  <h1 className="title">Export posts from page with ease.</h1>
                  <p>
                    MTK is a tool to export facebook posts from page. You may
                    use facebook posts to analyze, discover insight, to improve
                    your content strategies or other way you want.
                  </p>
                  <div style={{ margin: "1.2em auto" }}>
                    {loading && (
                      <Loading type="bubbles" width={42} color={"#3273dc"} />
                    )}
                    {!loading && !isLoggedIn && (
                      <button onClick={login} className="button is-link">
                        Login with Facebook
                      </button>
                    )}
                    {!loading && isLoggedIn && (
                      <>
                        <Link
                          className="button is-link"
                          to="/app"
                          style={{ margin: "0 10px 0 0" }}
                        >
                          Go to app
                        </Link>
                        <button onClick={logout} className="button is-danger">
                          Logout
                        </button>
                      </>
                    )}
                  </div>
                  <p className="has-text-grey is-family-code is-size-7">
                    * It's free.
                  </p>
                </div>
              </div>
              <div className="column">
                <div className="intro-image">
                  <img src={heroimg} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

const HomeWithAppContext = () => (
  <AppContextProvider>
    <Home />
  </AppContextProvider>
);

export default HomeWithAppContext;
