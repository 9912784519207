import React, { useState, useEffect, useContext } from "react";
import Loading from "react-loading";
import DatePicker from "react-date-picker";
import moment from "moment";
import { Link } from "react-router-dom";
import { AppContext, AppContextProvider } from "../AppContext";
import _ from "lodash";
import Layout from "./Layout";
import Pages from "../components/Pages";
import FB from "../libs/fb";
import API from "../libs/api";

const App = ({ history }) => {
  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [pages, setPages] = useState([]);
  const [searchedPages, setSearchedPages] = useState([]);
  const [nextPageCursor, setNextPageCursor] = useState("");
  const [selectedPage, setSelectedPage] = useState({});
  const [q, setQ] = useState("");
  const [date, setDate] = useState(new Date());
  const [showEmail, setShowEmail] = useState(false);
  const [email, setEmail] = useState("");
  const [sendJobLoading, setSendJobLoading] = useState(false);
  const [sendJobComplete, setSendJobComplete] = useState(false);
  const [invalidJob, setInvalidJob] = useState(false);
  const { token } = useContext(AppContext);

  useEffect(() => {
    (async () => {
      try {
        let response = await FB.getLoginStatus();
        if (response.status === "connected") {
          setIsLoggedIn(true);
          setLoading(false);
          let userInfo = await FB.getUserInfo();
          setEmail(userInfo.email);
        } else {
          setIsLoggedIn(false);
          setLoading(false);
          history.push("/");
        }
      } catch (e) {
        setIsLoggedIn(false);
        setLoading(false);
        history.push("/");
      }
    })();
  }, []);

  const getPages = async e => {
    e.preventDefault();
    let response = await FB.getPages(nextPageCursor);
    let newPages = response.data.map(p => ({
      id: p.id,
      name: p.name,
      category: p.category
    }));
    setPages(_.uniqBy([...pages, ...response.data], "id"));
    setNextPageCursor(
      response && response.paging && response.paging.next
        ? response.paging.cursors.after
        : ""
    );
  };

  const selectPage = page => {
    if (page.id === selectedPage.id) {
      setSelectedPage({});
    } else {
      setSelectedPage(page);
    }
  };

  const searchPages = async () => {
    let response = await FB.searchPages2(q);
    let newPages = response.data.map(p => ({
      id: p.id,
      name: p.name,
      category: "Unknown"
    }));
    setSearchedPages(newPages);
  };

  const searchPagesOnClick = async e => {
    e.preventDefault();
    await searchPages();
  };

  const searchPagesOnEnter = async e => {
    if (e.keyCode === 13) {
      await searchPages();
    }
  };

  const updateQuery = e => {
    setQ(e.target.value);
  };

  const isObjectEmpty = obj =>
    Object.keys(obj).length === 0 && obj.constructor === Object;

  const updateDate = date => {
    setDate(date);
    setShowEmail(true);
  };

  const updateEmail = e => {
    setEmail(e.target.value);
  };

  const sendJob = async e => {
    e.preventDefault();
    try {
      setSendJobLoading(true);
      let sendJobResponse = await API.sendJob(
        selectedPage.id,
        moment(date)
          .startOf("month")
          .format("YYYY-MM-DD"),
        moment(date)
          .endOf("month")
          .format("YYYY-MM-DD"),
        email,
        token
      );
      setSendJobLoading(false);
      setSendJobComplete(true);
    } catch (e) {
      setSendJobLoading(false);
      setInvalidJob(true);
    }
  };

  return (
    <Layout>
      <div style={{ margin: "1.2em auto", textAlign: "center" }}>
        {loading && (
          <Loading
            className="loading-center"
            type="bubbles"
            width={42}
            color={"#3273dc"}
          />
        )}
        {!loading && isLoggedIn && (
          <>
            <div className="has-margin-3">
              <button className="button is-link" onClick={getPages}>
                Select your page
              </button>
            </div>

            <Pages
              pages={pages}
              onElmClick={selectPage}
              selectedPage={selectedPage}
              classNames={["has-margin-3"]}
            />

            <div className="has-margin-2">
              <p>
                {nextPageCursor && (
                  <button className="button is-link" onClick={getPages}>
                    Load more
                  </button>
                )}
              </p>
            </div>

            <div className="has-margin-3">
              <p className="is-size-4">OR</p>
            </div>

            <div className="has-margin-3">
              <div className="columns is-centered">
                <div className="column is-half">
                  <div className="field has-addons has-margin-3">
                    <div className="control is-expanded">
                      <div className="is-fullwidth">
                        <div className="control">
                          <input
                            type="text"
                            className="input"
                            placeholder="Page name"
                            value={q}
                            onChange={updateQuery}
                            onKeyUp={searchPagesOnEnter}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="control">
                      <button
                        type="submit"
                        className="button is-link"
                        onClick={searchPagesOnClick}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {searchedPages && (
              <Pages
                pages={searchedPages}
                onElmClick={selectPage}
                selectedPage={selectedPage}
                classNames={["has-margin-3"]}
              />
            )}

            {!isObjectEmpty(selectedPage) && (
              <div className="has-margin-3">
                <h2 className="is-size-4 has-margin-3">Select month</h2>
                <DatePicker
                  onChange={updateDate}
                  value={date}
                  maxDetail="year"
                  minDetail="decade"
                  clearIcon={null}
                />
              </div>
            )}

            {showEmail && (
              <>
                <div className="has-margin-3">
                  <h2 className="is-size-4 has-margin-3">
                    Send exported file to email
                  </h2>

                  <div className="has-margin-3">
                    <div className="columns is-centered">
                      <div className="column is-half">
                        <div className="field has-addons has-margin-3">
                          <div className="control is-expanded">
                            <div className="is-fullwidth">
                              <div className="control">
                                <input
                                  type="email"
                                  className="input"
                                  placeholder="Email"
                                  value={email}
                                  onChange={updateEmail}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {!sendJobLoading && !sendJobComplete && !invalidJob && (
                  <div className="has-margin-3">
                    <button className="button is-link" onClick={sendJob}>
                      Submit
                    </button>
                  </div>
                )}
                {sendJobLoading && !sendJobComplete && (
                  <Loading
                    className="loading-center"
                    type="bubbles"
                    width={42}
                    color={"#3273dc"}
                  />
                )}
                {!sendJobLoading && sendJobComplete && (
                  <>
                    <div className="has-margin-3">
                      <p>
                        Yay! You just submit the export request. The system will
                        be export facebook posts for you and send to your email
                        soon :D
                      </p>
                    </div>
                    <div className="has-margin-3">
                      <Link
                        className="button is-link"
                        to="/app"
                        onClick={() => window.location.reload()}
                      >
                        Click here to export another page
                      </Link>
                    </div>
                  </>
                )}

                {invalidJob && (
                  <p>
                    Failed to export data from page.{" "}
                    <a
                      className="button is-text"
                      onClick={() => window.location.reload()}
                    >
                      Please try again
                    </a>
                  </p>
                )}
              </>
            )}
          </>
        )}
      </div>
    </Layout>
  );
};

const AppWithContextProvider = () => (
  <AppContextProvider>
    <App />
  </AppContextProvider>
);

export default AppWithContextProvider;
