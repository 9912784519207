import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

const Layout = ({ children }) => (
  <div className="app">
    <Header />
    <div className="section main">
      <div className="container">{children}</div>
    </div>
    <Footer />
  </div>
);

export default Layout;
