class FB {
  constructor(FB) {
    this.FB = FB;
  }

  async login() {
    return new Promise((resolve, reject) => {
      this.FB.login(
        response => {
          if (response.authResponse) {
            resolve(response.authResponse);
          } else {
            reject(response);
          }
        },
        { scope: "public_profile,email,pages_show_list", return_scopes: true }
      );
    });
  }

  async getUserInfo() {
    return new Promise((resolve, reject) => {
      this.FB.api("/me?fields=id,name,email", response => {
        resolve(response);
      });
    });
  }

  async getLoginStatus() {
    return new Promise((resolve, reject) => {
      this.FB.getLoginStatus(response => {
        if (response.status === "connected") {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  }

  async getPages(after = "") {
    return new Promise((resolve, reject) => {
      const path = after
        ? `/me/accounts?limit=6&after=${after}`
        : "/me/accounts?limit=6";
      this.FB.api(path, response => {
        resolve(response);
      });
    });
  }

  async searchPages(q) {
    return new Promise((resolve, reject) => {
      this.FB.api(`/pages/search?q=${q}`, response => {
        resolve(response);
      });
    });
  }

  async searchPages2(q) {
    let response = await fetch(
      `https://graph.facebook.com/pages/search?q="${q}&access_token=EAAVA5OEIChwBAHNrg0eWW1SfWHSLw12XDMHubiofCu8RCC1T6Y1pI8iKZAD3FGQhx0BaehaQ8RCbJWY2itZCu2TOSgZBFTeFDVa6ivSlT87svU4edjF5lD2rndvegNclZAgg8k3ozZBMqipBZC8On5GElwhwyGZBzUXZChsZAhpZB89QZDZD`
    );
    let result = await response.json();
    return result;
  }

  async logout() {
    return new Promise((resolve, reject) => {
      this.FB.logout(response => {
        resolve(response);
      });
    });
  }
}

export default new FB(window.FB);
